import Vue from 'vue';
import Vuex from 'vuex';
import { SET_AUTH_INFO, SET_PASSWORD } from '@/mutation-types';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    schoolCode: '',
    sassiId: '',
    lastName: '',
    firstName: '',
    birthDay: '',
    grades: [],
    password: '',
  },
  mutations: {
    [SET_AUTH_INFO](state, payload) {
      state.schoolCode = payload.schoolCode;
      state.sassiId = payload.sassiId;
      state.lastName = payload.lastName;
      state.firstName = payload.firstName;
      state.birthDay = payload.birthDay;
      state.grades = payload.grades;
    },
    [SET_PASSWORD](state, password) {
      state.password = password;
    },
  },
  actions: {
  },
});
