



















import axios from "axios";
import { Component, Vue, Prop } from "vue-property-decorator";

import { PUBLIC_NOTIFICATION_API_URL } from "@/variables";
import { Notification } from "@/utils";
import router from "@/router";

@Component
export default class PublicNotifications extends Vue {
  @Prop({ default: "sassi" }) readonly from!: string;

  private notifications: Notification[] = [];
  private errorMessage: string = "";

  private onNotification(notificationId: number): void {
    router.push({
      name: "notification",
      params: {
        from: this.from,
        id: String(notificationId),
      },
    });
  }

  private mounted(): void {
    axios
      .get(PUBLIC_NOTIFICATION_API_URL)
      .then((response) => {
        this.notifications = response.data.slice(0, 2);
      })
      .catch((error) => {
        console.error(error);
        this.errorMessage = "お知らせの取得に失敗しました。";
      });
  }
}
