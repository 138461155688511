
import { Mixins } from "vue-mixin-decorator";
import SessionHeaderMixin from "@/mixins/SessionHeaderMixin.vue";
import {
  COMMUNICATION_ERROR_MESSAGE,
  BENESSE_STUDENT_INFO_API_URL,
  BENESSE_CONTRACT_INFO_API_URL,
  BENESSE_MEMBER_INFO_API_URL,
  SIGN_IN_API_URL,
} from "@/variables";
import axios from "axios";
import { Session } from "@/utils";
import router from "@/router";

const FAILED_TO_SIGN_IN_MESSAGE: string = "ログインに失敗しました。";
const NO_VALID_CONTRACT_MESSAGE: string = "ご契約がありません。";
const NO_SASSI_ASSOCIATION_MESSAGE: string = "学校の先生によってMicrosoft,Googleアカウントの紐付けが行われていないようです。SASSI-IDでログインし直すか、学校の先生へお問い合わせください。";

export default class SassiHandler extends Mixins<SessionHeaderMixin>(
  SessionHeaderMixin) {

  public sassiId: string = "";
  public password: string = "";
  public grade: string = "";
  public schoolCode: string = "";
  public studentKbn: string = "";
  public ngakunendo: string = "";
  public memberKey: string = "";
  public errorMessage: string = "";
  public isLoaderOverlayOpen: boolean = false;

  public showErrorMessage(errorMessage: string) {
    this.isLoaderOverlayOpen = false;
    router.push({name: 'authError', query: {"message": errorMessage}});
  }

  public failedToCommunicate(error: any): void {
    console.error(error);
    this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE);
  }
  
  public getStudentInfo(): void {
    // 生徒情報取得
    const sessionHeader = this.getSessionHeader();
    if (sessionHeader == null) {
      this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
      return;
    }

    axios
      .get(BENESSE_STUDENT_INFO_API_URL, { headers: sessionHeader })
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.grade = data.gakunen;
          this.schoolCode = data.schoolCd;
          this.studentKbn = data.studentKbn;
          this.ngakunendo = data.ngakunendo;
          this.getContractInfo();
        } else {
          const errors = data.errors;
          const error = errors[0];
          if (error.cd.indexOf("student") > -1) {
            this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
          } else {
            this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE);
          }
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
      });
  }

  public getContractInfo(): void {
    // 受注情報取得
    const params = new URLSearchParams();
    params.append("schoolCd", this.schoolCode);

    const sessionHeader = this.getSessionHeader();
    if (sessionHeader == null) {
      this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
      return;
    }

    axios
      .post(BENESSE_CONTRACT_INFO_API_URL, params, { headers: sessionHeader })
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          const contracts = data.jyutyuList;
          if (contracts === void 0) {
            this.showErrorMessage(NO_VALID_CONTRACT_MESSAGE);
            return;
          }

          const grades: string[] = contracts.map((contract: any) => {
            return contract.gakunen;
          });
          if (grades.indexOf(this.grade) > -1) {
            this.getMemberInfo();
          } else {
            this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
          }
        } else {
          const error = data.errors[0];
          if (error.cd.indexOf("school") > -1) {
            this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
          } else {
            this.failedToCommunicate(data);
          }
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
      });
  }

  public getMemberInfo(): void {
    // 会員情報取得
    const params = new URLSearchParams();
    params.append("schoolCd", this.schoolCode);
    params.append("gakunen", this.grade);

    const sessionHeader = this.getSessionHeader();
    if (sessionHeader == null) {
      this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
      return;
    }

    axios
      .post(BENESSE_MEMBER_INFO_API_URL, params, { headers: sessionHeader })
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          if ("studentInfos" in data && data.studentInfos.length > 0) {
            this.memberKey = data.studentInfos[0].memberKey;
            this.signIn();
          } else {
            this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
          }
        } else {
          this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
      });
  }

  public authenticateWithSassi(url: string, params: object): void {
    this.isLoaderOverlayOpen = true;
    axios
      .post(url, params)
      .then((response) => {
        const data = response.data;
        if (data.result === 0 && data.authresult === "OK") {
          const sessionList: Session[] = data.session;
          const secureSessionList = sessionList.filter((s) => s.secure);
          if (secureSessionList.length > 0) {
            this.setSessionInCookie(secureSessionList[0]);
            this.setAuthIdInCookie(data.authid);
            this.getStudentInfo();
          } else {
            this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
          }
        } else if (data.result === 0 && data.authresult === "NG") {
          if (data.resultcode === "CHECK0003") {
            this.showErrorMessage(NO_SASSI_ASSOCIATION_MESSAGE);
          } else {
            // ユーザ IDまたはパスワードが違う場合
            this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
          }
        } else {
          this.failedToCommunicate(data);
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
      });
  }

  public signIn(): void {
    console.log("SassiHandler.signIn()");
    // ログイン
    const params = new URLSearchParams();
    params.append("member_key", this.memberKey);

    axios
      .post(SIGN_IN_API_URL, params)
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        this.failedToCommunicate(error);
      });
  }
}

