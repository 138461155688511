




























































































import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import moment from "moment";
import Loader from "@/components/Loader.vue";
import NameAndBirthdayValidationMixin from "@/mixins/NameAndBirthdayValidationMixin.vue";
import store from "@/store";
import { SET_AUTH_INFO } from "@/mutation-types";
import {
  SCHOOL_CODE_LENGTH,
  SASSI_ID_LENGTH,
  VALIDATE_FOR_REGISTRATION_API_URL,
  COMMUNICATION_ERROR_MESSAGE
} from "@/variables";

@Component({
  components: {
    Loader
  }
})
export default class SignUp1Form extends NameAndBirthdayValidationMixin {
  private schoolCode: string = "";
  private sassiId: string = "";

  private errorMessage: string = "";
  private schoolErrorMessage: string = "";
  private isLoaderOverlayOpen: boolean = false;

  private failedToCommunicate(error: any): void {
    console.error(error);
    this.errorMessage = COMMUNICATION_ERROR_MESSAGE;
    this.isLoaderOverlayOpen = false;
  }

  @Watch("schoolCode")
  onChangeSchoolCode(): void {
    this.schoolErrorMessage = "";
  }

  get schoolCodeValidationMessage(): string {
    const errorMessage = "学校コードを正しく入力してください。";
    if (this.schoolCode.length !== SCHOOL_CODE_LENGTH) {
      return errorMessage;
    }

    if (Number.isNaN(Number(this.schoolCode))) {
      return errorMessage;
    }

    return "";
  }

  get sassiIdValidationMessage(): string {
    const errorMessage = "SASSI-IDを正しく入力してください。";
    if (
      this.sassiId.length !== SASSI_ID_LENGTH ||
      this.sassiId.slice(0, 5) !== "SASSI" ||
      Number.isNaN(Number(this.sassiId.slice(5, 15)))
    ) {
      return errorMessage;
    }
    return "";
  }

  get isNextButtonDisabled(): boolean {
    const inputAll =
      this.schoolCode &&
      this.sassiId &&
      this.lastName &&
      this.firstName &&
      this.combinedBirthDay;
    const isAllValid = !(
      this.schoolCodeValidationMessage ||
      this.sassiIdValidationMessage ||
      this.lastNameValidationMessage ||
      this.firstNameValidationMessage ||
      this.fullNameMaxLengthValidationMessage ||
      this.birthDayValidationMessage
    );

    return !inputAll || !isAllValid;
  }

  private onNext(): void {
    this.errorMessage = "";
    this.schoolErrorMessage = "";
    this.isLoaderOverlayOpen = true;

    axios
      .post(VALIDATE_FOR_REGISTRATION_API_URL, {
        school_code: this.schoolCode,
        sassi_id: this.sassiId,
        last_name: this.lastName,
        first_name: this.firstName,
        birth_day: this.combinedBirthDay
      })
      .then(response => {
        const data = response.data;

        if (data.is_valid) {
          this.submit(data.grades);
        } else {
          if (data.error_message.indexOf("学校コード") !== -1) {
            this.schoolErrorMessage = data.error_message;
          } else {
            this.errorMessage = data.error_message;
          }
          this.isLoaderOverlayOpen = false;
        }
      })
      .catch(error => {
        this.failedToCommunicate(error);
      });
  }

  private submit(grades: string[]): void {
    store.commit(SET_AUTH_INFO, {
      schoolCode: this.schoolCode,
      sassiId: this.sassiId,
      lastName: this.lastName,
      firstName: this.firstName,
      birthDay: this.combinedBirthDay,
      grades
    });
    this.$emit("submit");
  }

  private mounted() {
    const birthDayInStore = this.$store.state.birthDay;
    if (birthDayInStore.length === 4) {
      this.schoolCode = this.$store.state.schoolCode;
      this.sassiId = this.$store.state.sassiId;
      this.lastName = this.$store.state.lastName;
      this.firstName = this.$store.state.firstName;
      this.birthMonth = birthDayInStore.slice(0, 2);
      this.birthDay = birthDayInStore.slice(2, 4);
    }
  }
}
