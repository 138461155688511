











import { Vue, Component } from "vue-property-decorator";
import {
  OPENID_STATE_KEY,
  OPENID_NONCE_KEY,
  MAIN_SERVER_DOMAIN,
  GOOGLE_CLIENT_ID,
  MICROSOFT_CLIENT_ID,
} from "@/variables";

@Component
export default class LoginOptions extends Vue {
  private state: string = "";
  private nonce: string = "";
  get googleURL() {
    return "https://accounts.google.com/o/oauth2/v2/auth?client_id=" + GOOGLE_CLIENT_ID +
      "&response_type=code&scope=openid%20email%20profile&redirect_uri=" + MAIN_SERVER_DOMAIN + "/user/auth/openid/g/&state=" +
      this.state + "&nonce=" + this.nonce;
  }
  get microsoftURL() {
    return "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" + MICROSOFT_CLIENT_ID +
    "&redirect_uri=" + MAIN_SERVER_DOMAIN + "/user/auth/openid/m/" + "&response_type=code&scope=openid%20email%20profile%20user.read" +
    "&state=" + this.state + "&nonce=" + this.nonce;
  }
  private createRandomString(): string {
    const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const N = 16;
    return Array.from(Array(N))
      .map(() => S[Math.floor(Math.random() * S.length)])
      .join("");
  }
  private mounted(): void {
    this.state = this.createRandomString();
    this.$cookies.set(OPENID_STATE_KEY, this.state, -1);
    this.nonce = this.createRandomString();
    this.$cookies.set(OPENID_NONCE_KEY, this.nonce, -1);
  }
}
