var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tt-app tt-signin-view"},[_c('div',{staticClass:"tt-app-header"},[_c('div',{staticClass:"tt-app-header-content"},[_c('div',{staticClass:"tt-header-left"},[_c('button',{staticClass:"tt-button",on:{"click":_vm.onBack}},[_c('img',{staticClass:"tt-icons",attrs:{"src":require("../assets/icons/white/Back.svg")}})])]),_c('div',{staticClass:"tt-title"},[_vm._v("Classi初回登録")]),_c('div',{staticClass:"tt-header-right"})])]),_c('div',{staticClass:"tt-app-container tt-center"},[_c('div',{staticClass:"tt-app-container-block"},[_vm._m(0),_c('p',[_vm._v("ユーザー登録")]),_c('div',[(_vm.errorMessage)?_c('p',{staticClass:"tt-error"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('div',{staticClass:"tt-form-group-row"},[_c('div',{staticClass:"tt-form-group",class:{
              'tt-error':
                _vm.lastName &&
                (_vm.lastNameValidationMessage !== '' ||
                  _vm.fullNameMaxLengthValidationMessage !== ''),
            }},[_c('label',{staticClass:"tt-form-label"},[_vm._v("姓（カナ）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"tt-input",attrs:{"type":"text"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}})]),_c('div',{staticClass:"tt-form-group",class:{
              'tt-error':
                _vm.firstName &&
                (_vm.firstNameValidationMessage !== '' ||
                  _vm.fullNameMaxLengthValidationMessage !== ''),
            }},[_c('label',{staticClass:"tt-form-label"},[_vm._v("名（カナ）")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"tt-input",attrs:{"type":"text"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}})])]),(_vm.lastName && _vm.lastNameValidationMessage !== '')?_c('div',{staticClass:"tt-error tt-form-group-row-error"},[_vm._v("\n          "+_vm._s(_vm.lastNameValidationMessage)+"\n        ")]):_vm._e(),(_vm.firstName && _vm.firstNameValidationMessage !== '')?_c('div',{staticClass:"tt-error tt-form-group-row-error"},[_vm._v("\n          "+_vm._s(_vm.firstNameValidationMessage)+"\n        ")]):_vm._e(),(
            _vm.lastName && _vm.firstName && _vm.fullNameMaxLengthValidationMessage !== ''
          )?_c('div',{staticClass:"tt-error tt-form-group-row-error"},[_vm._v("\n          "+_vm._s(_vm.fullNameMaxLengthValidationMessage)+"\n        ")]):_vm._e(),_c('div',{staticClass:"tt-form-group-row"},[_c('div',{staticClass:"tt-form-group",class:{
              'tt-error':
                _vm.birthMonth &&
                (_vm.birthMonthValidationMessage !== '' ||
                  _vm.combinedBirthDayValidationMessage !== ''),
            }},[_c('label',{staticClass:"tt-form-label"},[_vm._v("誕生月")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.birthMonth),expression:"birthMonth"}],staticClass:"tt-input",attrs:{"type":"text","pattern":"\\d{1,2}"},domProps:{"value":(_vm.birthMonth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.birthMonth=$event.target.value}}})]),_c('span',{staticClass:"bitrhday-separator"},[_vm._v(" / ")]),_c('div',{staticClass:"tt-form-group",class:{
              'tt-error':
                _vm.birthDay &&
                (_vm.birthDayValidationMessage !== '' ||
                  _vm.combinedBirthDayValidationMessage !== ''),
            }},[_c('label',{staticClass:"tt-form-label"},[_vm._v("誕生日")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.birthDay),expression:"birthDay"}],staticClass:"tt-input",attrs:{"type":"text","pattern":"\\d{1,2}"},domProps:{"value":(_vm.birthDay)},on:{"input":function($event){if($event.target.composing){ return; }_vm.birthDay=$event.target.value}}})])]),(_vm.birthDayValidationMessage !== '')?_c('div',{staticClass:"tt-error tt-form-group-row-error"},[_vm._v("\n          "+_vm._s(_vm.birthDayValidationMessage)+"\n        ")]):_vm._e(),(_vm.birthMonthValidationMessage !== '')?_c('div',{staticClass:"tt-error tt-form-group-row-error"},[_vm._v("\n          "+_vm._s(_vm.birthMonthValidationMessage)+"\n        ")]):_vm._e(),(_vm.combinedBirthDayValidationMessage !== '')?_c('div',{staticClass:"tt-error tt-form-group-row-error"},[_vm._v("\n          "+_vm._s(_vm.combinedBirthDayValidationMessage)+"\n        ")]):_vm._e()])])]),_c('button',{staticClass:"tt-button tt-block tt-primary",attrs:{"disabled":_vm.isNextButtonDisabled},on:{"click":_vm.onNext}},[_vm._v("\n    次へ\n  ")]),_c('LegalLinks'),_c('Loader',{attrs:{"isOverlayOpen":_vm.isLoaderOverlayOpen}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"tt-signup-pagination"},[_c('strong',[_vm._v("1")]),_vm._v(" / 5")])}]

export { render, staticRenderFns }