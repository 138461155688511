import Vue from 'vue';
import Router from 'vue-router';
import LandingPage from './views/LandingPage.vue';
import SignIn from './views/SignIn.vue';
import SignUp1 from './views/SignUp1.vue';
import SignUp2 from './views/SignUp2.vue';
import ValidateStudentInfoBeforeSetPassword from './views/ValidateStudentInfoBeforeSetPassword.vue';
import SetPassword from './views/SetPassword.vue';
import ClassiAuth from './views/ClassiAuth.vue';
import ClassiAuthSignIn from './views/ClassiAuthSignIn.vue';
import ClassiAuthSignUp1 from './views/ClassiAuthSignUp1.vue';
import ClassiAuthSignUp2 from './views/ClassiAuthSignUp2.vue';
import NotificationDetail from './views/NotificationDetail.vue';
import OpenIDHandler from './components/OpenIDHandler.vue';
import LoginOptionsPage from './views/LoginOptionsPage.vue';
import AuthErrorPage from './views/OpenIDNotAssociated.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'landingPage',
      component: LandingPage,
    },
    {
      path: '/options',
      name: 'loginOptions',
      component: LoginOptionsPage,
    },
    {
      path: '/signin',
      name: 'signIn',
      component: SignIn,
    },
    {
      path: '/signup1',
      name: 'signUp1',
      component: SignUp1,
    },
    {
      path: '/signup2',
      name: 'signUp2',
      component: SignUp2,
    },
    {
      path: '/validate-student-info-before-set-password',
      name: 'validateStudentInfoBeforeSetPassword',
      component: ValidateStudentInfoBeforeSetPassword,
    },
    {
      path: '/set-password',
      name: 'setPassword',
      component: SetPassword,
      props: true,
    },
    {
      path: '/classi',
      name: 'classiAuth',
      component: ClassiAuth,
    },
    {
      path: '/classi/signin',
      name: 'classiAuthSignIn',
      component: ClassiAuthSignIn,
    },
    {
      path: '/classi/signup/1',
      name: 'classiAuthSignUp1',
      component: ClassiAuthSignUp1,
    },
    {
      path: '/classi/signup/2',
      name: 'classiAuthSignUp2',
      component: ClassiAuthSignUp2,
    },
    {
      path: '/notification/:from/:id',
      name: 'notification',
      component: NotificationDetail,
    },
    {
      path: '/errors/show',
      name: 'authError',
      component: AuthErrorPage,
    },
    {
      path: '/openid/:provider',
      name: 'openIdHandler',
      component: OpenIDHandler,
    },
  ],
});
