








import { Component } from "vue-mixin-decorator";
import Loader from "@/components/Loader.vue";
import {
  OPENID_STATE_KEY,
  MAIN_SERVER_DOMAIN,
  OPENID_NONCE_KEY,
} from "@/variables";
import SassiHandler from "./SassiHandler.vue";

const FAILED_TO_SIGN_IN_MESSAGE: string = "ログインに失敗しました。";

@Component({
  components: {
    Loader,
  },
})
export default class OpenIDHandler extends SassiHandler {

  private async mounted() {
    if (
      typeof this.$route.query.code === "string" &&
      typeof this.$route.query.state === "string"
    ) {
      const code: string = this.$route.query.code;
      const state: string = this.$route.query.state;

      if (code && state) {
        const stateInCookie = this.$cookies.get(OPENID_STATE_KEY);
        if (state !== stateInCookie) {
          console.error('"state" is not same as in Cookie.');
          console.error('state: ' + state);
          console.error('state in cookie: ' + stateInCookie);
          this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
        } else {
          this.$cookies.remove(OPENID_STATE_KEY);

          const params = {
            code: code,
            provider: this.$route.params.provider,
            nonce: this.$cookies.get(OPENID_NONCE_KEY),
          };

          this.$cookies.remove(OPENID_NONCE_KEY);

          this.authenticateWithSassi(MAIN_SERVER_DOMAIN + "/user/api/auth/openid/", params);
        }
      } else {
        console.error('"code" or "state" is missing in GET parameters.');
        this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
      }
    } else {
      this.showErrorMessage(FAILED_TO_SIGN_IN_MESSAGE);
    }
  }

}
