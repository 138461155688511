





















import { Component, Vue } from "vue-property-decorator";
@Component
export default class AuthErrorPage extends Vue {

  message: string = "";

  mounted() {
    this.message = this.$route.query.message.toString();
  }
}
