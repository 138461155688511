


































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SignUp2Form extends Vue {
  private password: string = '';
  private agreed: boolean = false;

  get sassiId(): string {
    return this.$store.state.sassiId;
  }

  get isRegistrationButtonDisabled(): boolean {
    return !(this.agreed && this.sassiId && this.password);
  }

  private onRegister() {
    this.$emit('register', this.password);
  }

  private mounted() {
    if (!this.$store.state.sassiId) {
      this.$emit('noSassiId');
    }
    if (this.$store.state.password) {
      this.password = this.$store.state.password;
    }
  }
}
