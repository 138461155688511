import { CLASSI_LOGOUT_URL } from './variables';

export interface Session {
  secure: boolean;
  id: string;
  name: string;
}

export interface SessionHeader {
  [key: string]: string;
}

export interface ClassiTokens {
  access_token: string;
  refresh_token: string;
}

export interface Notification {
  id: number;
  subject: string;
  body: string;
  sent_at: string;
}

export function convertErrorCodeToErrorMessage(errorCode: string): string {
  switch (errorCode) {
    case 'auth.error':
      return '認証に失敗しました。';
    case 'studentData.nodata':
      return 'ベネッセIDの登録状況を先生にご確認ください。';
    case 'classiuserInfo.nodata':
      return 'ベネッセIDの登録状況を先生にご確認ください。';
    case 'classiuserInfo.typeerr':
      return 'ユーザタイプに誤りがあります。先生にご確認ください。';
    case 'classiuserInfo.dberr':
      return '認証に失敗しました。';
    case 'system.error':
      return 'サーバーエラーが発生いたしました。';
    case 'otherSystem.error':
      return '連携先システムとの通信に失敗しました。';
    case 'otherSystem.noconnect':
      return '連携先システムとの通信に失敗しました。';
    case 'otherSystem.overparam':
      return '連携先システムとの通信に失敗しました。';
    case 'studentId.notmatch':
      return '入力内容に誤りがあります。';
    default:
      return 'サーバーエラーが発生いたしました。';
  }
}

export function moveToClassiLogoutPage(errorCode: string): boolean {
  if (['classiuserInfo.typeerr', 'classiuserInfo.nodata', 'studentData.nodata'].includes(errorCode)) {
    return true;
  }
  return false;
}
