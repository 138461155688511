


























































import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import LegalLinks from "@/components/LegalLinks.vue";
import Loader from "@/components/Loader.vue";
import ClassiAccessTokenMixin from "@/mixins/ClassiAccessTokenMixin.vue";
import {
  BENESSE_CLASSI_STUDENT_INFO_API_URL,
  BENESSE_CLASSI_AGREEMENT_API_URL,
  BENESSE_CLASSI_REGISTRATION_API_URL,
  REGISTER_API_URL,
  CLASSI_ACCESS_TOKEN_KEY,
  CLASSI_REFRESH_TOKEN_KEY,
  BENESSE_API_ERROR_CODES,
  COMMUNICATION_ERROR_MESSAGE,
} from "@/variables";
import { convertErrorCodeToErrorMessage } from "@/utils";

@Component({
  components: {
    LegalLinks,
    Loader,
  },
})
export default class ClassiAuthSignUp2 extends ClassiAccessTokenMixin {
  private accessToken: string = "";
  private refreshToken: string = "";

  private agreed: boolean = false;
  private schoolCode: string = "";
  private grade: string = "";
  private studentKbn: string = "";
  private ngakunendo: string = "";
  private memberKey: string = "";

  private isLoaderOverlayOpen: boolean = false;
  private errorMessage: string = "";

  get isRegistrationButtonDisabled(): boolean {
    return !this.agreed;
  }

  private onRegister() {
    this.errorMessage = "";
    this.isLoaderOverlayOpen = true;
    this.getStudentInfo();
  }

  private showErrorMessage(message: string, error: any) {
    console.error(error);
    this.isLoaderOverlayOpen = false;
    this.errorMessage = message;
  }

  private handleBenesseAPIError(data: any) {
    if (data.errors) {
      const error = data.errors[0];
      const errorCode = error.cd;
      const handled: boolean = this.handleAPIErrorRelatedToClassi(errorCode);
      if (!handled) {
        if (
          errorCode === BENESSE_API_ERROR_CODES.SASSI_ID_IS_ALREADY_REGISTERED
        ) {
          this.showErrorMessage("このアカウントはすでに登録済みです。", {});
        } else if (!handled && errorCode === "auth.error") {
          window.alert("認証に失敗しました。");
          this.$router.push({ name: "classiAuth" });
        } else {
          const errorMessage = convertErrorCodeToErrorMessage(errorCode);
          this.showErrorMessage(errorMessage, data);
        }
      }
    } else {
      this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE, data);
    }
  }

  private getStudentInfo() {
    // 生徒情報取得API
    const params = new URLSearchParams();
    params.append("accessToken", this.accessToken);
    axios
      .post(BENESSE_CLASSI_STUDENT_INFO_API_URL, params)
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.schoolCode = data.schoolCd;
          this.grade = data.gakunen;
          this.studentKbn = data.studentKbn;
          this.ngakunendo = data.ngakunendo;

          this.agree();
        } else {
          this.handleBenesseAPIError(data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response) {
          const data = response.data;
          this.handleBenesseAPIError(data);
        } else {
          this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE, error);
        }
      });
  }

  private agree() {
    const params = new URLSearchParams();
    params.append("accessToken", this.accessToken);
    params.append("usableFlg", "1");
    axios
      .post(BENESSE_CLASSI_AGREEMENT_API_URL, params)
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.registerWithB();
        } else {
          this.handleBenesseAPIError(data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response) {
          const data = response.data;
          this.handleBenesseAPIError(data);
        } else {
          this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE, error);
        }
      });
  }

  private registerWithB() {
    const params = new URLSearchParams();
    params.append("accessToken", this.accessToken);
    params.append("studentKbn", this.studentKbn);
    params.append("ngakunendo", this.ngakunendo);
    params.append("schoolCd", this.schoolCode);
    params.append("gakunen", this.grade);
    axios
      .post(BENESSE_CLASSI_REGISTRATION_API_URL, params)
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.memberKey = data.memberInfos[0].memberKey;
          this.register();
        } else {
          this.handleBenesseAPIError(data);
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response) {
          const data = response.data;
          this.handleBenesseAPIError(data);
        } else {
          this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE, error);
        }
      });
  }

  private register() {
    const params = new URLSearchParams();
    params.append("member_key", this.memberKey);

    axios
      .post(REGISTER_API_URL, params)
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE, error);
      });
  }

  private mounted() {
    const accessToken = this.$cookies.get(CLASSI_ACCESS_TOKEN_KEY);
    const refreshToken = this.$cookies.get(CLASSI_REFRESH_TOKEN_KEY);
    if (accessToken && refreshToken) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    } else {
      this.$router.push({ name: "classiAuth" });
    }
  }
}
