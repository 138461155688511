











































import { Component } from "vue-mixin-decorator";
import axios from "axios";
import Loader from "@/components/Loader.vue";
import SassiHandler from "@/components/SassiHandler.vue";
import {
  SIGN_IN_B_API_URL,
} from "@/variables";
import { Session } from "@/utils";

const FAILED_TO_SIGN_IN_MESSAGE: string = "ログインに失敗しました。";

@Component({
  components: {
    Loader,
  },
})
export default class SignInForm extends SassiHandler {

  get isSignInButtonDisabled(): boolean {
    return !(this.sassiId && this.password);
  }

  private onSignIn(): void {
    const params = {
      user_id: this.sassiId,
      password: this.password,
    };
    this.authenticateWithSassi(SIGN_IN_B_API_URL, params);
  }

  private mounted(): void {
    if (this.$store.state.sassiId) {
      this.sassiId = this.$store.state.sassiId;
    } else {
      const sassiIdInput = this.$refs.sassiIdInput;
      if (sassiIdInput instanceof HTMLInputElement) {
        this.sassiId = sassiIdInput.value;
      }
    }
    const passwordInput = this.$refs.passwordInput;
    if (passwordInput instanceof HTMLInputElement) {
      this.password = passwordInput.value;
    }
  }
}
