





















import { Component, Vue } from "vue-property-decorator";
import SignInForm from "@/components/SignInForm.vue";
import PublicNotifications from "@/components/PublicNotifications.vue";
import LegalLinks from "@/components/LegalLinks.vue";

@Component({
  components: {
    PublicNotifications,
    SignInForm,
    LegalLinks,
  },
})
export default class SignIn extends Vue {}
