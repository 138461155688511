



























import { Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import axios from "axios";
import router from "@/router";
import SessionHeaderMixin from "@/mixins/SessionHeaderMixin.vue";
import SignUpForm2 from "@/components/SignUpForm2.vue";
import LegalLinks from "@/components/LegalLinks.vue";
import Loader from "@/components/Loader.vue";
import {
  COMMUNICATION_ERROR_MESSAGE,
  BENESSE_AUTH_API_URL,
  BENESSE_APP_ID,
  BENESSE_STUDENT_INFO_API_URL,
  BENESSE_AGREEMENT_API_URL,
  BENESSE_REGISTRATION_API_URL,
  REGISTER_API_URL,
  SIGN_IN_B_API_URL,
} from "@/variables";
import { locales } from "moment";

interface Session {
  secure: boolean;
  id: string;
  name: string;
}

const INVALID_DATA_ERROR_MESSAGE = "入力内容に誤りがあります。";

@Component({
  components: {
    SignUpForm2,
    LegalLinks,
    Loader,
  },
})
export default class SignUp2 extends Mixins<SessionHeaderMixin>(
  SessionHeaderMixin
) {
  private grade: string = "";
  private studentKbn: string = "";
  private ngakunendo: string = "";
  private memberKey: string = "";

  private isLoaderOverlayOpen: boolean = false;
  private errorMessage: string = "";

  private backToSignUp1(): void {
    router.push({ name: "signUp1" });
  }

  private failedToCommunicate(error: any): void {
    console.error(error);
    this.errorMessage = COMMUNICATION_ERROR_MESSAGE;
  }

  private onRegister(password: string): void {
    this.isLoaderOverlayOpen = true;
    const params = {
      user_id: this.$store.state.sassiId,
      password: password,
    };
    axios
      .post(SIGN_IN_B_API_URL, params)
      .then((response) => {
        const data = response.data;

        if (data.result === 0 && data.authresult === "OK") {
          const sessionList: Session[] = data.session;
          const secureSessionList = sessionList.filter((s) => s.secure);
          if (secureSessionList.length > 0) {
            this.setSessionInCookie(secureSessionList[0]);
            this.setAuthIdInCookie(data.authid);
            this.getStudentInfo();
          } else {
            this.errorMessage = COMMUNICATION_ERROR_MESSAGE;
            this.isLoaderOverlayOpen = false;
          }
        } else if (data.result === 0 && data.authresult === "NG") {
          // ユーザ IDまたはパスワードが違う場合
          this.errorMessage = INVALID_DATA_ERROR_MESSAGE;
          this.isLoaderOverlayOpen = false;
        } else {
          this.failedToCommunicate(data);
          this.isLoaderOverlayOpen = false;
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
        this.isLoaderOverlayOpen = false;
      });
  }

  private getStudentInfo(): void {
    // 生徒情報取得API
    const sessionHeader = this.getSessionHeader();
    if (sessionHeader == null) {
      this.failedToCommunicate("No Session Info in Cookie");
      this.isLoaderOverlayOpen = false;
      return;
    }

    axios
      .get(BENESSE_STUDENT_INFO_API_URL, { headers: sessionHeader })
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.grade = data.gakunen;
          if (this.$store.state.grades.indexOf(this.grade) > -1) {
            this.studentKbn = data.studentKbn;
            this.ngakunendo = data.ngakunendo;
            this.agree();
          } else {
            this.errorMessage = INVALID_DATA_ERROR_MESSAGE;
            this.isLoaderOverlayOpen = false;
          }
        } else {
          const errors = data.errors;
          const error = errors[0];
          if (error.cd.indexOf("student") > -1) {
            this.errorMessage = INVALID_DATA_ERROR_MESSAGE;
          } else {
            this.failedToCommunicate(data);
          }
          this.isLoaderOverlayOpen = false;
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
        this.isLoaderOverlayOpen = false;
      });
  }

  private agree(): void {
    // 生徒利用規約同意API
    const sessionHeader = this.getSessionHeader();
    if (sessionHeader == null) {
      this.failedToCommunicate("No Session Info in Cookie");
      this.isLoaderOverlayOpen = false;
      return;
    }

    const params = new URLSearchParams();
    params.append("usableFlg", "1");

    axios
      .post(BENESSE_AGREEMENT_API_URL, params, { headers: sessionHeader })
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.registerWithB();
        } else {
          const errors = data.errors;
          const error = errors[0];
          if (error.cd.indexOf("student") > -1) {
            this.errorMessage = INVALID_DATA_ERROR_MESSAGE;
          } else {
            this.failedToCommunicate(data);
          }
          this.isLoaderOverlayOpen = false;
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
        this.isLoaderOverlayOpen = false;
      });
  }

  private registerWithB(): void {
    // 初回登録API
    const sessionHeader = this.getSessionHeader();
    if (sessionHeader == null) {
      this.failedToCommunicate("No Session Info in Cookie");
      this.isLoaderOverlayOpen = false;
      return;
    }

    const params = new URLSearchParams();
    params.append("studentKbn", this.studentKbn);
    params.append("ngakunendo", this.ngakunendo);
    params.append("schoolCd", this.$store.state.schoolCode);
    params.append("gakunen", this.grade);
    params.append("classiFlg", "0");
    params.append("toBFlg", "1");

    axios
      .post(BENESSE_REGISTRATION_API_URL, params, { headers: sessionHeader })
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.memberKey = data.memberInfos[0].memberKey;
          this.register();
        } else {
          const errors = data.errors;
          const error = errors[0];
          if (error.cd === "AZR001.exists") {
            this.errorMessage = "このSASSI-IDはすでに登録済みです。";
          } else {
            this.failedToCommunicate(data);
          }
          this.isLoaderOverlayOpen = false;
        }
      })
      .catch((error) => {
        this.failedToCommunicate(error);
        this.isLoaderOverlayOpen = false;
      });
  }

  private register(): void {
    const params = new URLSearchParams();
    params.append("member_key", this.memberKey);

    axios
      .post(REGISTER_API_URL, params)
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch((error) => {
        this.failedToCommunicate(error);
        this.isLoaderOverlayOpen = false;
      });
  }
}
