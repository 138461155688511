
























import { Component, Vue } from "vue-property-decorator";
import SignUpForm1 from "@/components/SignUpForm1.vue";
import LegalLinks from "@/components/LegalLinks.vue";
import router from "@/router";

@Component({
  components: {
    SignUpForm1,
    LegalLinks,
  },
})
export default class ValidateStudentInfoBeforeSetPassword extends Vue {
  private onNextButton(): void {
    router.push({
      name: "setPassword",
      params: {
        refererName: "validateStudentInfoBeforeSetPassword",
        nextName: "signIn",
      },
    });
  }
}
