
import axios from "axios";
import { Vue } from "vue-property-decorator";
import { Mixin } from "vue-mixin-decorator";
import {
  B_SESSION_NAME_KEY,
  B_AUTH_ID_KEY,
  CLASSI_ERROR_MESSAGES,
  CLASSI_LOGOUT_URL
} from "@/variables";
import {
  ClassiTokens,
  convertErrorCodeToErrorMessage,
  moveToClassiLogoutPage
} from "@/utils";

@Mixin
export default class ClassiAccessTokenMixin extends Vue {
  async getAccessToken(code: string, authType: string): Promise<ClassiTokens> {
    const params = {
      code: code,
      auth_type: authType
    };
    const axiosResp = await axios.post("/user/api/classi/token/", params);
    return axiosResp.data;
  }

  async refreshAccessToken(refresh_token: string): Promise<ClassiTokens> {
    const params = { refresh_token: refresh_token };
    const axiosResp = await axios.post("/user/api/classi/token/", params);
    return axiosResp.data;
  }

  public showClassiErrorMessage() {
    window.alert(CLASSI_ERROR_MESSAGES.COMMUNICATION_ERROR);
    if ((window as any).Native) {
      (window as any).Native.clearCookie();
      this.$router.push({ name: "classiAuth" });
    } else {
      location.href = CLASSI_LOGOUT_URL;
    }
  }

  public handleAPIErrorRelatedToClassi(errorCode: string): boolean {
    if (errorCode.startsWith("classi")) {
      const errorMessage = convertErrorCodeToErrorMessage(errorCode);
      window.alert(errorMessage);
      const needToLogout = moveToClassiLogoutPage(errorCode);
      if (needToLogout) {
        if ((window as any).Native) {
          (window as any).Native.clearCookie();
          this.$router.push({ name: "classiAuth" });
        } else {
          location.href = CLASSI_LOGOUT_URL;
        }
      } else {
        this.$router.push({ name: "classiAuth" });
      }
      return true;
    }
    return false;
  }
}
