
import { Vue } from "vue-property-decorator";
import { Mixin } from "vue-mixin-decorator";
import moment from "moment";
import { MAX_FULL_NAME_LENGTH } from "@/variables";

function isZenkakuKana(value: string): boolean {
  return /^[ァ-ヶー　]+$/.test(value);
}

@Mixin
export default class NameAndBirthDayValidationMixin extends Vue {
  public lastName: string = "";
  public firstName: string = "";
  public birthMonth: string = "";
  public birthDay: string = "";

  get lastNameValidationMessage(): string {
    if (!isZenkakuKana(this.lastName)) {
      return "姓を全角カナで入力してください。";
    }

    return "";
  }

  get firstNameValidationMessage(): string {
    if (!isZenkakuKana(this.firstName)) {
      return "名を全角カナで入力してください。";
    }

    return "";
  }

  get fullNameMaxLengthValidationMessage(): string {
    if (
      this.lastNameValidationMessage !== "" ||
      this.firstNameValidationMessage !== ""
    ) {
      return "";
    }

    if ((this.lastName + this.firstName).length > MAX_FULL_NAME_LENGTH) {
      return (
        "姓名合わせて" + MAX_FULL_NAME_LENGTH + "文字以内で入力してください。"
      );
    }

    return "";
  }

  get birthMonthValidationMessage(): string {
    const birthMonth = Number(this.birthMonth);
    if (Number.isNaN(birthMonth)) {
      return "半角数字で入力してください。";
    }

    if (birthMonth < 0 || birthMonth > 12) {
      return "誕生日を正しく入力してください。";
    }

    return "";
  }

  get birthDayValidationMessage(): string {
    const birthDay = Number(this.birthDay);
    if (Number.isNaN(birthDay)) {
      return "半角数字で入力してください。";
    }

    if (birthDay < 0 || birthDay > 31) {
      return "誕生日を正しく入力してください。";
    }

    return "";
  }

  get combinedBirthDayValidationMessage(): string {
    if (
      this.combinedBirthDay === "" ||
      this.birthMonthValidationMessage !== "" ||
      this.birthDayValidationMessage !== ""
    ) {
      return "";
    }

    if (Number.isNaN(Number(this.combinedBirthDay))) {
      return "半角数字で入力してください。";
    }

    if (this.combinedBirthDay === "0000") {
      return "";
    }

    const errorMessage = "誕生日を正しく入力してください。";
    if (this.combinedBirthDay.length !== 4) {
      return errorMessage;
    }

    const date = moment("2020" + this.combinedBirthDay, "YYYYMMDD");
    if (!date.isValid()) {
      return errorMessage;
    }

    return "";
  }

  get combinedBirthDay(): string {
    if (this.birthMonth && this.birthDay) {
      let combinedBirthDay =
        this.birthMonth.length === 1 ? "0" + this.birthMonth : this.birthMonth;
      combinedBirthDay +=
        this.birthDay.length === 1 ? "0" + this.birthDay : this.birthDay;

      return combinedBirthDay;
    } else {
      return "";
    }
  }
}
