







































































































































import axios from "axios";
import { Component } from "vue-property-decorator";
import { Mixin, Mixins } from "vue-mixin-decorator";
import ClassiAccessTokenMixin from "@/mixins/ClassiAccessTokenMixin.vue";
import NameAndBirthdayValidationMixin from "@/mixins/NameAndBirthdayValidationMixin.vue";
import LegalLinks from "@/components/LegalLinks.vue";
import Loader from "@/components/Loader.vue";
import {
  BENESSE_CLASSI_VALIDATE_FOR_REGISTRATION_API_URL,
  BENESSE_CLASSI_STUDENT_INFO_API_URL,
  BENESSE_CLASSI_MEMBER_INFO_API_URL,
  SIGN_IN_API_URL,
  CLASSI_ACCESS_TOKEN_KEY,
  CLASSI_REFRESH_TOKEN_KEY,
  CLASSI_STATE_KEY,
  COMMUNICATION_ERROR_MESSAGE,
  CLASSI_LOGOUT_URL,
} from "@/variables";
import { ClassiTokens, convertErrorCodeToErrorMessage } from "@/utils";

interface IMixinInterface
  extends ClassiAccessTokenMixin,
    NameAndBirthdayValidationMixin {}

@Component({
  components: {
    LegalLinks,
    Loader,
  },
})
export default class ClassiAuthSignUp1 extends Mixins<IMixinInterface>(
  ClassiAccessTokenMixin,
  NameAndBirthdayValidationMixin
) {
  private accessToken: string = "";
  private refreshToken: string = "";

  private isLoaderOverlayOpen: boolean = false;
  private errorMessage: string = "";

  get isNextButtonDisabled(): boolean {
    const inputAll = this.lastName && this.firstName && this.combinedBirthDay;
    const isAllValid = !(
      this.lastNameValidationMessage ||
      this.firstNameValidationMessage ||
      this.fullNameMaxLengthValidationMessage ||
      this.birthDayValidationMessage
    );

    return !inputAll || !isAllValid;
  }

  private onBack() {
    if ((window as any).Native) {
      (window as any).Native.clearCookie();
      this.$router.push({ name: "classiAuth" });
    } else {
      location.href = CLASSI_LOGOUT_URL;
    }
  }

  private onNext() {
    this.isLoaderOverlayOpen = true;
    this.validateForRegistration();
  }

  private handleBenesseAPIError(data: any) {
    console.error(data);
    if (data.errors) {
      const error = data.errors[0];
      const errorCode = error.cd;
      let handled: boolean = this.handleAPIErrorRelatedToClassi(errorCode);
      if (!handled && errorCode === "auth.error") {
        window.alert("認証に失敗しました。");
        this.$router.push({ name: "classiAuth" });
      }
      if (!handled) {
        this.errorMessage = convertErrorCodeToErrorMessage(errorCode);
      }
    } else {
      this.errorMessage = COMMUNICATION_ERROR_MESSAGE;
    }
  }

  private validateForRegistration() {
    const params = new URLSearchParams();
    params.append("accessToken", this.accessToken);
    params.append("nameKana", `${this.lastName} ${this.firstName}`);
    params.append("birthday", this.combinedBirthDay);
    axios
      .post(BENESSE_CLASSI_VALIDATE_FOR_REGISTRATION_API_URL, params)
      .then((response) => {
        const data = response.data;
        if (data.result === 0) {
          this.$cookies.set(CLASSI_ACCESS_TOKEN_KEY, this.accessToken, -1);
          this.$cookies.set(CLASSI_REFRESH_TOKEN_KEY, this.refreshToken, -1);
          this.$router.push({ name: "classiAuthSignUp2" });
        } else {
          this.handleBenesseAPIError(data);
          this.isLoaderOverlayOpen = false;
        }
      })
      .catch((error) => {
        const response = error.response;
        if (response) {
          const data = response.data;
          this.handleBenesseAPIError(data);
        } else {
          console.error(error);
          this.errorMessage = COMMUNICATION_ERROR_MESSAGE;
        }
        this.isLoaderOverlayOpen = false;
      });
  }

  private async mounted() {
    if (
      typeof this.$route.query.code === "string" &&
      typeof this.$route.query.state === "string"
    ) {
      const code: string = this.$route.query.code;
      const state: string = this.$route.query.state;

      if (code && state) {
        const stateInCookie = this.$cookies.get(CLASSI_STATE_KEY);
        if (state !== stateInCookie) {
          console.error('"state" is not same as in Cookie.');
          this.showClassiErrorMessage();
        } else {
          this.$cookies.remove(CLASSI_STATE_KEY);

          try {
            const tokens: ClassiTokens = await this.getAccessToken(
              code,
              "signup"
            );
            this.accessToken = tokens.access_token;
            this.refreshToken = tokens.refresh_token;
          } catch (e) {
            console.error(e);
            this.showClassiErrorMessage();
          }
        }
      } else {
        console.error('"code" or "state" is missing in GET parameters.');
        this.showClassiErrorMessage();
      }
    } else {
      const accessToken = this.$cookies.get(CLASSI_ACCESS_TOKEN_KEY);
      const refreshToken = this.$cookies.get(CLASSI_REFRESH_TOKEN_KEY);
      if (accessToken && refreshToken) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
      } else {
        console.error('"accessToken" or "refreshToken" is missing in Cookie.');
        this.showClassiErrorMessage();
      }
    }
  }
}
