
import { Vue } from "vue-property-decorator";
import { Mixin } from "vue-mixin-decorator";
import { B_SESSION_NAME_KEY, B_AUTH_ID_KEY } from "@/variables";
import { Session, SessionHeader } from "@/utils";

@Mixin
export default class SessionHeaderMixin extends Vue {
  public setSessionInCookie(session: Session): void {
    let date = new Date();
    date.setDate(date.getDate() + 90);
    this.$cookies.set(B_SESSION_NAME_KEY, session.name, date);
    this.$cookies.set(session.name, session.id, date);
  }

  public setAuthIdInCookie(authId: string): void {
    let date = new Date();
    date.setDate(date.getDate() + 90);
    this.$cookies.set(B_AUTH_ID_KEY, authId, date);
  }

  public getSessionHeader(): SessionHeader | null {
    const keyName = this.$cookies.get(B_SESSION_NAME_KEY);
    if (keyName === null) {
      return null;
    }

    const sessionId = this.$cookies.get(keyName);
    if (sessionId === null) {
      return null;
    }

    const sessionHeader: SessionHeader = {};
    sessionHeader[keyName] = sessionId;

    return sessionHeader;
  }
}
