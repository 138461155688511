





























import axios from "axios";
import { Component } from "vue-property-decorator";
import ClassiAccessTokenMixi from "@/mixins/ClassiAccessTokenMixin.vue";
import {
  BENESSE_CLASSI_STUDENT_INFO_API_URL,
  BENESSE_CLASSI_MEMBER_INFO_API_URL,
  SIGN_IN_API_URL,
  COMMUNICATION_ERROR_MESSAGE,
  CLASSI_ACCESS_TOKEN_KEY,
  CLASSI_REFRESH_TOKEN_KEY,
  CLASSI_STATE_KEY
} from "@/variables";
import { ClassiTokens, convertErrorCodeToErrorMessage } from "@/utils";

@Component
export default class ClassiAuthSignIn extends ClassiAccessTokenMixi {
  private accessToken: string = "";
  private refreshToken: string = "";

  private showErrorMessage(message: string) {
    window.alert(message);
    this.$router.push({ name: "classiAuth" });
  }

  private handleBenesseAPIError(data: any) {
    console.error(data);
    if (data.errors) {
      const error = data.errors[0];
      const errorCode = error.cd;
      let handled: boolean = false;
      if (errorCode === "studentInfo.nodata") {
        this.showErrorMessage("ログインに失敗しました。");
        handled = true;
      } else {
        handled = this.handleAPIErrorRelatedToClassi(errorCode);
      }
      if (!handled) {
        const errorMessage = convertErrorCodeToErrorMessage(errorCode);
        this.showErrorMessage(errorMessage);
      }
    } else {
      this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE);
    }
  }

  private getStudentInfo() {
    // 生徒情報取得API
    const params = new URLSearchParams();
    params.append("accessToken", this.accessToken);
    axios
      .post(BENESSE_CLASSI_STUDENT_INFO_API_URL, params)
      .then(response => {
        const data = response.data;
        if (data.result === 0) {
          const schoolCode = data.schoolCd;
          const grade = data.gakunen;

          this.getMemberInfo(schoolCode, grade);
        } else {
          this.handleBenesseAPIError(data);
        }
      })
      .catch(error => {
        const response = error.response;
        if (response) {
          const data = response.data;
          this.handleBenesseAPIError(data);
        } else {
          console.error(error);
          this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE);
        }
      });
  }

  private getMemberInfo(schoolCode: string, grade: string) {
    // 会員情報取得API
    const params = new URLSearchParams();
    params.append("accessToken", this.accessToken);
    params.append("schoolCd", schoolCode);
    params.append("gakunen", grade);
    axios
      .post(BENESSE_CLASSI_MEMBER_INFO_API_URL, params)
      .then(response => {
        const data = response.data;
        if (data.result === 0) {
          if ("studentInfos" in data && data.studentInfos.length > 0) {
            const memberKey: string = data.studentInfos[0].memberKey;
            this.signIn(memberKey);
          } else {
            this.$cookies.set(CLASSI_ACCESS_TOKEN_KEY, this.accessToken, -1);
            this.$cookies.set(CLASSI_REFRESH_TOKEN_KEY, this.refreshToken, -1);
            this.$router.push({ name: "classiAuthSignUp1" });
          }
        } else {
          this.handleBenesseAPIError(data);
        }
      })
      .catch(error => {
        const response = error.response;
        if (response) {
          const data = response.data;
          this.handleBenesseAPIError(data);
        } else {
          console.error(error);
          this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE);
        }
      });
  }

  private signIn(memberKey: string) {
    // ログイン
    const params = new URLSearchParams();
    params.append("member_key", memberKey);

    axios
      .post(SIGN_IN_API_URL, params)
      .then(response => {
        this.$cookies.set(CLASSI_ACCESS_TOKEN_KEY, this.accessToken, -1);
        this.$cookies.set(CLASSI_REFRESH_TOKEN_KEY, this.refreshToken, -1);
        window.location.href = response.data.url;
      })
      .catch(error => {
        console.error(error);
        this.showErrorMessage(COMMUNICATION_ERROR_MESSAGE);
      });
  }

  private async mounted() {
    if (
      typeof this.$route.query.code === "string" &&
      typeof this.$route.query.state === "string"
    ) {
      const code: string = this.$route.query.code;
      const state: string = this.$route.query.state;

      if (code && state) {
        const stateInCookie = this.$cookies.get(CLASSI_STATE_KEY);
        if (state !== stateInCookie) {
          console.error('"state" is not same as in Cookie.');
          this.showClassiErrorMessage();
        } else {
          this.$cookies.remove(CLASSI_STATE_KEY);

          try {
            const tokens: ClassiTokens = await this.getAccessToken(
              code,
              "signin"
            );
            this.accessToken = tokens.access_token;
            this.refreshToken = tokens.refresh_token;

            this.getStudentInfo();
          } catch (e) {
            console.error(e);
            this.showClassiErrorMessage();
          }
        }
      } else {
        console.error('"code" or "state" is missing in GET parameters.');
        this.showClassiErrorMessage();
      }
    } else {
      const accessToken = this.$cookies.get(CLASSI_ACCESS_TOKEN_KEY);
      const refreshToken = this.$cookies.get(CLASSI_REFRESH_TOKEN_KEY);
      if (accessToken && refreshToken) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;

        this.getStudentInfo();
      } else {
        console.error('"accessToken" or "refreshToken" is missing in Cookie.');
        this.showClassiErrorMessage();
      }
    }
  }
}
