





















import { Component, Vue } from "vue-property-decorator";
import LoginOptions from "@/components/LoginOptions.vue";
import LegalLinks from "@/components/LegalLinks.vue";

@Component({
  components: {
    LoginOptions,
    LegalLinks,
  }
})

export default class LoginOptionsPage extends Vue {}
