












































































import { Component, Prop, Vue } from "vue-property-decorator";
import axios from "axios";
import LegalLinks from "@/components/LegalLinks.vue";
import Loader from "@/components/Loader.vue";
import router from "@/router";
import { SET_PASSWORD_API_URL, COMMUNICATION_ERROR_MESSAGE } from "@/variables";

@Component({
  components: {
    LegalLinks,
    Loader,
  },
})
export default class SetPassword extends Vue {
  @Prop()
  private refererName?: string;
  @Prop()
  private nextName?: string;

  private password1: string = "";
  private password2: string = "";
  private errorMessage: string = "";
  private isLoaderOverlayOpen: boolean = false;

  get password1ValidationMessage(): string {
    if (this.password1.length < 8 || this.password1.length > 16) {
      return "8文字以上16文字以内で入力してください。";
    } else if (!/[a-zA-Z]+/.test(this.password1)) {
      return "半角英字【a~z, A~Z】を1文字以上含めてください。";
    } else if (!/[0-9]+/.test(this.password1)) {
      return "半角数字を1文字以上含めてください。";
    } else if (!/^[0-9a-zA-z!#$%&,\-./=?@[\]^_{}~]*$/.test(this.password1)) {
      return "使用できない文字が含まれています。";
    } else if (/(.)\1{2,}/.test(this.password1)) {
      return "同一の文字が3つ以上連続して含まれています。";
    }
    return "";
  }

  get password2ValidationMessage(): string {
    if (this.password2 !== this.password1) {
      return "パスワードが異なっています。";
    }
    return "";
  }

  get isSubmitButtonDisabled(): boolean {
    return !(
      this.password1ValidationMessage === "" &&
      this.password2ValidationMessage === "" &&
      this.password1 === this.password2
    );
  }

  private onSet(): void {
    this.isLoaderOverlayOpen = true;
    const state = this.$store.state;
    axios
      .post(SET_PASSWORD_API_URL, {
        school_code: state.schoolCode,
        sassi_id: state.sassiId,
        last_name: state.lastName,
        first_name: state.firstName,
        birth_day: state.birthDay,
        password: this.password1,
      })
      .then((response) => {
        this.isLoaderOverlayOpen = false;
        router.push({ name: this.nextName });
      })
      .catch((error) => {
        this.isLoaderOverlayOpen = false;
        console.error(error);
        this.errorMessage = COMMUNICATION_ERROR_MESSAGE;
      });
  }

  private mounted(): void {
    const state = this.$store.state;

    if (
      !(
        state.schoolCode &&
        state.sassiId &&
        state.lastName &&
        state.firstName &&
        state.birthDay
      )
    ) {
      router.push({ name: "signIn" });
    } else if (!this.refererName || !this.nextName) {
      router.push({ name: "signIn" });
    }
  }
}
