























































import { Vue } from "vue-property-decorator";
import {
  URL_CHECK_ENV,
  ENV_NAME,
} from "@/variables";
export default class LandingPage extends Vue {
  get checkEnvLink() {
    return URL_CHECK_ENV;
  }
  get whichEnv() {
    if (ENV_NAME !== null && ENV_NAME !== "" && ENV_NAME !== "Production") {
      return "Environment: " + ENV_NAME;
    }
    return "";
  }
}
