





























import axios from "axios";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

import { PUBLIC_NOTIFICATION_API_URL } from "@/variables";
import { Notification } from "@/utils";
import router from "@/router";

@Component
export default class NotificationDetail extends Vue {
  notification!: Notification;
  loaded: boolean = false;

  get backTo(): object {
    if (this.$route.params.from === "sassi") {
      return { name: "signIn" };
    } else {
      return { name: "classiAuth" };
    }
  }

  private getSentAt(sentAtStr: string): string {
    const now = moment();
    const sentAt = moment(sentAtStr);
    if (sentAt.format("YYYYMMDD") === now.format("YYYYMMDD")) {
      return sentAt.format("HH:mm");
    } else {
      return sentAt.format("YYYY/MM/DD");
    }
  }

  private getNotification(notificationId: number): void {
    axios
      .get(PUBLIC_NOTIFICATION_API_URL)
      .then(response => {
        const notifications = response.data.filter(
          (notification: Notification) => {
            return notification.id === notificationId;
          }
        );
        if (notifications.length > 0) {
          this.notification = notifications[0];
          this.loaded = true;
        } else {
          router.push(this.backTo);
        }
      })
      .catch(error => {
        console.error(error);
        window.alert("お知らせの取得に失敗しました。");
        router.push(this.backTo);
      });
  }

  private mounted(): void {
    const notificationId = Number(this.$route.params.id);
    if (isNaN(notificationId)) {
      router.push(this.backTo);
    } else {
      this.getNotification(notificationId);
    }
  }
}
