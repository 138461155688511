import { render, staticRenderFns } from "./ClassiAuthSignUp1.vue?vue&type=template&id=3ef6ee58&scoped=true&"
import script from "./ClassiAuthSignUp1.vue?vue&type=script&lang=ts&"
export * from "./ClassiAuthSignUp1.vue?vue&type=script&lang=ts&"
import style0 from "./ClassiAuthSignUp1.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ClassiAuthSignUp1.vue?vue&type=style&index=1&id=3ef6ee58&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef6ee58",
  null
  
)

export default component.exports