

































import { Component, Vue } from "vue-property-decorator";
import router from "@/router";
import PublicNotifications from "@/components/PublicNotifications.vue";
import LegalLinks from "@/components/LegalLinks.vue";
import {
  CLASSI_AUTH_REQUEST_URL,
  CLASSI_CLIENT_ID,
  CLASSI_SIGNUP_REDIRECT_URL,
  CLASSI_SIGNIN_REDIRECT_URL,
  CLASSI_STATE_KEY,
} from "@/variables";

@Component({
  components: {
    PublicNotifications,
    LegalLinks,
  },
})
export default class ClassiAuth extends Vue {
  private state: string = "";

  get classiAuthRequestUrlForSignIn(): string {
    return (
      this.classiAuthRequestUrl +
      `&redirect_uri=${encodeURIComponent(CLASSI_SIGNIN_REDIRECT_URL)}`
    );
  }

  get classiAuthRequestUrlForSignUp(): string {
    return (
      this.classiAuthRequestUrl +
      `&redirect_uri=${encodeURIComponent(CLASSI_SIGNUP_REDIRECT_URL)}`
    );
  }

  get classiAuthRequestUrl(): string {
    return (
      CLASSI_AUTH_REQUEST_URL +
      `?response_type=code&client_id=${CLASSI_CLIENT_ID}&scope=${encodeURIComponent(
        "openid profile"
      )}&state=${this.state}`
    );
  }

  private createState(): string {
    const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const N = 23;
    return Array.from(Array(N))
      .map(() => S[Math.floor(Math.random() * S.length)])
      .join("");
  }

  private mounted(): void {
    this.state = this.createState();
    this.$cookies.set(CLASSI_STATE_KEY, this.state, -1);
  }
}
