export const SCHOOL_CODE_LENGTH = 5;
export const SASSI_ID_LENGTH = 15;
export const MAX_FULL_NAME_LENGTH = 15;  // (lastName + lastName).langth

export const COMMUNICATION_ERROR_MESSAGE = '通信に失敗しました。';

export const CLASSI_SIGNUP_REDIRECT_URL = (window as any).CLASSI_SIGNUP_REDIRECT_URL;
export const CLASSI_SIGNIN_REDIRECT_URL = (window as any).CLASSI_SIGNIN_REDIRECT_URL;

export const ENV_NAME = (window as any).ENV_NAME;
export const URL_CHECK_ENV = (window as any).URL_CHECK_ENV;

// Not B APIs
export const MAIN_SERVER_DOMAIN = (window as any).MAIN_SERVER_DOMAIN;
export const GOOGLE_CLIENT_ID = (window as any).GOOGLE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = (window as any).MICROSOFT_CLIENT_ID;
export const SIGN_IN_B_API_URL = MAIN_SERVER_DOMAIN + '/user/api/b/signin/';
export const VALIDATE_FOR_REGISTRATION_API_URL = MAIN_SERVER_DOMAIN + '/user/api/validate_for_registration/';
export const SET_PASSWORD_API_URL = MAIN_SERVER_DOMAIN + '/user/api/set_password/';
export const REGISTER_API_URL = MAIN_SERVER_DOMAIN + '/user/api/register/';
export const SIGN_IN_API_URL = MAIN_SERVER_DOMAIN + '/user/api/signin/';

// B APIs
export const BENESSE_APP_ID = (window as any).BENESSE_APP_ID;
export const BENESSE_API_DOMAIN = (window as any).BENESSE_API_DOMAIN;
export const BENESSE_AUTH_API_DOMAIN = (window as any).BENESSE_AUTH_API_DOMAIN;
export const CLASSI_URL = (window as any).CLASSI_URL;
export const CLASSI_CLIENT_ID = (window as any).CLASSI_CLIENT_ID;
export const CLASSI_LOGOUT_URL = (window as any).CLASSI_LOGOUT_URL;
export const B_SESSION_NAME_KEY = 'B-SESSION-NAME-KEY';
export const B_AUTH_ID_KEY = 'B-AUTH-ID';
export const CLASSI_STATE_KEY = 'TTF-CLASSI-STATE';
export const OPENID_STATE_KEY = 'TTF-OPENID-STATE';
export const OPENID_NONCE_KEY = 'TTF-OPENID-NONCE';
export const CLASSI_ACCESS_TOKEN_KEY = 'TTF-CLASSI-ACCESS-TOKEN';
export const CLASSI_REFRESH_TOKEN_KEY = 'TTF-CLASSI-REFRESH-TOKEN';
export const BENESSE_AUTH_API_URL = BENESSE_AUTH_API_DOMAIN + '/appapi/authapi';  // 認証
export const BENESSE_STUDENT_INFO_API_URL = BENESSE_API_DOMAIN + '/fourskillsapps/api/AZA004'; // 生徒情報取得API
export const BENESSE_AGREEMENT_API_URL = BENESSE_API_DOMAIN + '/fourskillsapps/api/AZA003'; // 生徒利用規約同意API
export const BENESSE_REGISTRATION_API_URL = BENESSE_API_DOMAIN + '/fourskillsapps/api/AZR001'; // 初回登録API
export const BENESSE_CONTRACT_INFO_API_URL = BENESSE_API_DOMAIN + '/fourskillsapps/api/AZM001'; // 受注情報取得API
export const BENESSE_MEMBER_INFO_API_URL = BENESSE_API_DOMAIN + '/fourskillsapps/api/AZR002'; // 会員情報取得API
export const CLASSI_AUTH_REQUEST_URL = CLASSI_URL + '/oauth/authorize'; // 認可リクエスト
export const BENESSE_CLASSI_STUDENT_INFO_API_URL = BENESSE_API_DOMAIN + '/fourskillsclassis/api/AZA004'; // 生徒情報取得API
export const BENESSE_CLASSI_MEMBER_INFO_API_URL = BENESSE_API_DOMAIN + '/fourskillsclassis/api/AZR002'; // 会員情報取得API
export const BENESSE_CLASSI_VALIDATE_FOR_REGISTRATION_API_URL =
  BENESSE_API_DOMAIN + '/fourskillsclassis/api/AZA001'; // 会員情報取得API
export const BENESSE_CLASSI_AGREEMENT_API_URL = BENESSE_API_DOMAIN + '/fourskillsclassis/api/AZA003'; // 生徒利用規約同意API
export const BENESSE_CLASSI_REGISTRATION_API_URL = BENESSE_API_DOMAIN + '/fourskillsclassis/api/AZR001'; // 初回登録API

export const BENESSE_API_ERROR_CODES = {
  SASSI_ID_IS_ALREADY_REGISTERED: 'AZR001.exists',
};

export const CLASSI_ERROR_MESSAGES = {
  COMMUNICATION_ERROR: 'Classiサービスとの連携に失敗しました。',
};

export const PUBLIC_NOTIFICATION_API_URL = (window as any).PUBLIC_NOTIFICATION_API_URL;
